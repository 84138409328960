<template>
  <div>
    <h3 ref="ca">
      Transversal
    </h3>
    <p class="mt-2">
      Imagine you're given a pair of lines (<i>l</i> and <i>m</i> ) lying on the same plane.
      Imagine another line (<i>n</i> ) that intersects these two lines. This line is called a transversal. <br>
    </p>
    <br>
    <h3 ref="ca">
      Pairs of Angle made by Transversal
    </h3>
    <p class="mt-2">
      The transversal <i>n</i> makes four angles at the point of intersection with
      <i>l</i> &mdash; say &alpha;, &beta;, &gamma; and &delta;. Similarly, the transversal <i>n</i> makes four angles at the point of intersection with <i>m</i> &mdash; say &alpha;', &beta;', &gamma;' and &delta;'.
    </p>
    <br>
    <h3 ref="playgraph">
      MagicGraph &vert; Pairs of Angles made by a Transversal
    </h3>
    <p class="mt-2">
      This MagicGraph offers a visually interactive illustration of various pairs of angle formed when a pair of lines is intersected by a transversal.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Angles',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Angles made by a Transversal';
    this.$store.commit('navigation/changeTitle', title);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Pairs of Angles',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about pairs of angles made by a transversal'}
                ]
        }
   },
}
</script>
