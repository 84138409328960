import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
    placeArc,
    placeLogo,
    placeErase,
    placeShuffle,
    placeTest,
    placeSeg,
    setConfig,
    placeStat,
    placeLeft,
    placeRight,
    drawSegment,
    toggle,
    writeHTMLText
} from '../Utils';
const Boxes = {
  box1: function () {
  JXG.Options.board.minimizeReflow = 'none';
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-6, 8, 10, -8], keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.options.point.showInfobox =false;
brd1.options.point.highlight =false;
//brd1.suspendUpdate();
//Making the board responsive
makeResponsive(brd1);
//placing a Logo
placeLogo(brd1);
//placing title
placeTitle(brd1, 'Pairs of Angles made by a Transversal', '(Tap on a name to highlight)');
//
placeLeftText(brd1, 3, 4.5, 'Pairs of corresponding angles');
var txt1 =writeHTMLText(brd1, 4.5, -4.5, 'Pairs are: ( &angle; &alpha;, &angle; &alpha;\' ),  ( &angle;&beta;, &angle;&beta;\' ), ( &angle;&gamma;, &angle;&gamma;\' ), ( &angle;&delta;, &angle;&delta;\' )');
txt1.setAttribute({color:'blue', visible:()=>i==1});
//brd1.create('text', [1, 5, 'Corresponding angles'],{ visible: false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}});
placeLeftText(brd1, 3, 2.5, 'Pairs of alternate interior angles');
var txt2 =writeHTMLText(brd1, 4.5, -4.5, 'Pairs are: ( &angle;&alpha;, &angle;&alpha;\' ) & ( &angle;&beta;, &angle;&beta;\' )');
txt2.setAttribute({color:'blue', visible:()=>j==1});
//
placeLeftText(brd1, 3, 0.5, 'Pairs of alternate exterior angles');
//
var txt3 =writeHTMLText(brd1, 4.5, -4.5, 'Pairs are: ( &angle;&alpha;, &angle;&alpha;\' ) & ( &angle;&beta;, &angle;&beta;\' )');
txt3.setAttribute({color:'blue', visible:()=>k==1});
//
placeLeftText(brd1, 3, -1.5, 'Pairs of interior angles on same side');
var txt4 =writeHTMLText(brd1, 4.5, -4.5, 'Pairs are: ( &angle;&alpha;, &angle;&alpha;\' ) & ( &angle;&beta;, &angle;&beta;\' )');
txt4.setAttribute({color:'blue', visible:()=>l==1});
//placeLeftText(brd1, 3, -3.5, 'Pairs of interior angles');
//
var pTrans11 = brd1.create('point', [-6,-6],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var pTrans12 = brd1.create('point', [0, 0],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
writeHTMLText(brd1, 0.5, 0.5, '<i>l</i>');
var pVert11  = brd1.create('point', [-3,-5],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var pVert12 = brd1.create('point', [-3,3],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
writeHTMLText(brd1, -3, 3.5, '<i>n</i>');
//
var pTrans21 = brd1.create('point', [-6, -3],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var pTrans22 = brd1.create('point', [0, 3],{visible: false, name:' ', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:false, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
writeHTMLText(brd1, 0.5, 3.5, '<i>m</i>');
//Arrows
var arrVert11 = drawSegment(brd1, pVert11, pVert12);
arrVert11.setAttribute({strokeColor:'blue'});
var arrTrans11 = drawSegment(brd1, pTrans21, pTrans22);
arrTrans11.setAttribute({strokeColor:'red'});
var arrTrans21 = drawSegment(brd1, pTrans11, pTrans12);
arrTrans21.setAttribute({strokeColor:'red'});
//Angles
var angle1 = brd1.create('angle', [arrVert11, arrTrans11 , 1 ,-1],{name:'&alpha;' , visible:false, radius:0.5, strokeColor:'blue', fillColor:'blue', label: {color: 'blue', offset: [-5 , 15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle2 = brd1.create('angle', [arrTrans11 , arrVert11, 1 ,1],{name:'&beta;' , visible:false,  strokeColor:'grey', fillColor:'grey', label: {color: 'black', offset: [-5 , 15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle3 = brd1.create('angle', [arrTrans11 , arrVert11, -1 ,-1],{name:'&gamma;' , visible: false,  strokeColor:'green', fillColor:'green', label: {color: 'green', offset: [-5 ,10],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle4 = brd1.create('angle', [arrVert11, arrTrans11 , -1 ,1],{name:'&delta;' , visible: false,  strokeColor:'red', fillColor:'red', label: {color: 'red', offset: [5 ,0],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle5 = brd1.create('angle', [arrVert11, arrTrans21 , 1 ,-1],{name:'&alpha;\'' , visible: false,  strokeColor:'orange', fillColor:'yellow', label: {color: 'orange', offset: [-5 ,10],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle6 = brd1.create('angle', [arrTrans21 , arrVert11, 1 ,1],{name:'&beta;\'' , visible: false,  strokeColor:'purple', fillColor:'purple', label: {color: 'black', offset: [-5 , 15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle7 = brd1.create('angle', [arrTrans21 , arrVert11, -1 ,-1],{name:'&gamma;\'' , visible: false,  strokeColor:'magenta', fillColor:'magenta', label: {color: 'magenta', offset: [-5 , 10],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle8 = brd1.create('angle', [arrVert11, arrTrans21 , -1 ,1],{name:'&delta;\'' , visible: false,  strokeColor:'#69B7F2', fillColor:'#69B7F2', label: {color: '#69B7F2', offset: [5 , 10],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var circ01 = placeImage(brd1,'/assets/circleWhite.svg', 1.5, 4);
var circ11 = placeImage(brd1,'/assets/circle.svg', 1.5, 4);
circ11.setAttribute({visible:false});
var i=0; var j=0; var k=0; var l=0;
circ01.on('down', function(){
  eraseAngles();j=0;k=0;l=0;
  if(i==0){
    circ11.setAttribute({visible:true});
    angle1.setAttribute({visible: true, strokeColor:'blue', fillColor:'blue'});
    angle5.setAttribute({visible: true, strokeColor:'blue', fillColor:'blue', label: {color: 'blue'}});
//
    angle2.setAttribute({visible: true, strokeColor:'orange', fillColor:'orange'});
    angle6.setAttribute({visible: true, strokeColor:'orange', fillColor:'orange', label: {color: 'black'}});
//
    angle3.setAttribute({visible: true, strokeColor:'green', fillColor:'green'});
    angle7.setAttribute({visible: true, strokeColor:'green', fillColor:'green', label: {color: 'green'}});
//
    angle4.setAttribute({visible: true, strokeColor:'red', fillColor:'red'});
    angle8.setAttribute({visible: true, strokeColor:'red', fillColor:'red', label: {color: 'red'}});
  }
  else {
    circ11.setAttribute({visible:false});
    angle1.setAttribute({visible:false});
    angle2.setAttribute({visible:false});
    angle3.setAttribute({visible:false});
    angle4.setAttribute({visible:false});
    angle5.setAttribute({visible:false});
    angle6.setAttribute({visible:false});
    angle7.setAttribute({visible:false});
    angle8.setAttribute({visible:false});
  }
  i = toggle(i);
});

var circ02 = placeImage(brd1,'/assets/circleWhite.svg', 1.5, 2);
var circ12 = placeImage(brd1,'/assets/circle.svg', 1.5, 2);
circ12.setAttribute({visible:()=>j==1});
circ02.on('down', function(){
  eraseAngles();i=0;k=0;l=0;
  if(j==0){
    circ12.setAttribute({visible:true});
    angle3.setAttribute({name:'&beta;', visible: true, strokeColor:'green', fillColor:'green'});
    angle6.setAttribute({visible: true, strokeColor:'green', fillColor:'green', label: {color: 'green'}});
//
    angle4.setAttribute({name:'&alpha;',visible: true, strokeColor:'red', fillColor:'red'});
    angle5.setAttribute({visible: true, strokeColor:'red', fillColor:'red' , label: {color: 'red'}});
  }
  else {
    circ12.setAttribute({visible:false});
    angle1.setAttribute({visible:false});
    angle2.setAttribute({visible:false});
    angle3.setAttribute({visible:false});
    angle4.setAttribute({visible:false});
    angle5.setAttribute({visible:false});
    angle6.setAttribute({visible:false});
    angle7.setAttribute({visible:false});
    angle8.setAttribute({visible:false});
  }
  j = toggle(j);
});
///////////////////
var circ03 = placeImage(brd1,'/assets/circleWhite.svg', 1.5, 0);
var circ13 = placeImage(brd1,'/assets/circle.svg', 1.5, 0);
circ13.setAttribute({visible:false});
circ03.on('down', function(){
  eraseAngles();i=0;j=0;l=0;
  if(k==0){
    circ13.setAttribute({visible:true});
    angle1.setAttribute({visible: true, strokeColor:'blue', fillColor:'blue'});
    angle8.setAttribute({name:'&alpha;\'',visible: true, strokeColor:'blue', fillColor:'blue', label: {color: 'blue'}});
//
    angle2.setAttribute({visible: true, strokeColor:'red', fillColor:'red'});
    angle7.setAttribute({name:'&beta;\'', visible: true, strokeColor:'red', fillColor:'red', label: {color: 'red'}});
  }
  else {
    circ13.setAttribute({visible:false});
    angle1.setAttribute({visible:false});
    angle2.setAttribute({visible:false});
    angle3.setAttribute({visible:false});
    angle4.setAttribute({visible:false});
    angle5.setAttribute({visible:false});
    angle6.setAttribute({visible:false});
    angle7.setAttribute({visible:false});
    angle8.setAttribute({visible:false});
  }
  k = toggle(k);
});
////////////////////////////////////////////////////
var circ04 = placeImage(brd1,'/assets/circleWhite.svg', 1.5, -2);
var circ14 = placeImage(brd1,'/assets/circle.svg', 1.5, -2);
circ14.setAttribute({visible:false});
circ04.on('down', function(){
  eraseAngles();i=0;j=0;k=0;
  if(l==0){
    circ14.setAttribute({visible:true});
    angle3.setAttribute({name:'&alpha;',visible: true, strokeColor:'green', fillColor:'green'});
    angle5.setAttribute({visible: true, strokeColor:'green', fillColor:'green', label: {color: 'green'}});

    angle4.setAttribute({name:'&beta;', visible: true, strokeColor:'red', fillColor:'red'});
    angle6.setAttribute({visible: true, strokeColor:'red', fillColor:'red', label: {color: 'red'}});
  }
  else {
    circ14.setAttribute({visible:false});
    angle1.setAttribute({visible:false});
    angle2.setAttribute({visible:false});
    angle3.setAttribute({visible:false});
    angle4.setAttribute({visible:false});
    angle5.setAttribute({visible:false});
    angle6.setAttribute({visible:false});
    angle7.setAttribute({visible:false});
    angle8.setAttribute({visible:false});
  }
  l = toggle(l);
});
////////////////////////////////////////
function eraseAngles(){
    circ11.setAttribute({visible:false});
    circ12.setAttribute({visible:false});
    circ13.setAttribute({visible:false});
    circ14.setAttribute({visible:false});
    angle1.setAttribute({visible: false});
    angle2.setAttribute({visible: false});
    angle3.setAttribute({visible: false});
    angle4.setAttribute({visible: false});
    angle5.setAttribute({visible: false});
    angle6.setAttribute({visible: false});
    angle7.setAttribute({visible: false});
    angle8.setAttribute({visible: false});
}
/*
var circ05 = placeImage(brd1,'/assets/circleWhite.svg', 1.5, -4);
var circ15 = placeImage(brd1,'/assets/circle.svg', 1.5, -4);
circ15.setAttribute({visible:false});
////////////////
var circ06 = placeImage(brd1,'/assets/circleWhite.svg', 1.5, -6);
var circ16 = placeImage(brd1,'/assets/circle.svg', 1.5, -6);
circ16.setAttribute({visible:false});
*/
},
}
export default Boxes;
